<template>
  <v-row wrap no-gutters class="fill-height">
    <v-row class="mt-2 ml-3 align-center">
      <v-btn
        icon
        color="primary"
        @click="savePng"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-select
        v-model="userRelationsLevel"
        :items="relationsLevelValues"
        :label="$lang.labels.selectRelationsLevel"
        outlined
        dense
        required
        class="relations-level-diagram-select"
      ></v-select>
      <v-btn
        color="primary"
        class="ml-1"
        @click="onResetClick('reset')"
      >
        Reset
      </v-btn>
      <v-progress-circular
        v-if="isDiagramLoading"
        color="primary"
        indeterminate
        class="mt-1 ml-2"
      />
    </v-row>
    <div id="diagram" class="diagram"></div>
    <v-dialog v-if="showGenerationModal" v-model="showGenerationModal" max-width="80%">
      <v-card class="pa-2 generation-modal">
        <v-card-title class="ml-2">
          <span>{{ $lang.labels.generation }}</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="primary"
            text
            large
            data-cy="close-yes-no-dialog"
            @click="closeGenerationDialog()"
          >
            X
          </v-btn>
        </v-card-title>
        <GenerationCard
          :entity-id="entityIdSelectedForGenerate"
          :valid="true"
          :user-can-edit="canUserEditSelectedForGenerateEntity"
          :is-edit="isEdit"
          :is-dirty="isDirty"
          :lock="lock"
          :user-can-delete="canUserDeleteSelectedForGenerateEntity"
          :simple-error="simpleError"
          :success-persistence-messages-array="successPersistenceMessagesArray"
          :success-resources-messages-array="successResourcesMessagesArray"
          :trigger-force-logic-persistence="triggerForceLogicPersistence"
          :trigger-force-logic-resources="triggerForceLogicResources"
          :currently-open-delete-action="currentlyOpenDeleteAction"
          :delete-success="deleteSuccess"
          @deletePersistenceFunct="deletePersistence"
          @deleteResourcesFunct="deleteResources"
          @resetValidateGenerateDelete="resetValidateGenerateDelete"
        />
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import cytoscape from 'cytoscape'
import cytoscapeDomNode from 'cytoscape-dom-node'
import panzoom from 'cytoscape-panzoom'
import download from 'downloadjs'
import html2canvas from 'html2canvas'
import { getEntityByIdUsingGET as getEntity,
  entityPersistenceValidateUsingGET as validatePersistence } from '@/utils/api'
import GenerationCard from '../ui/modals/GenerationCard'

export default {
  name: 'EntityDiagram',
  components: {
    GenerationCard
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    fields: {
      type: Array,
      default: () =>  []
    },
    usages: {
      type: Array,
      default: () =>  []
    },
    userCanEdit: {
      type: Boolean,
      default: false
    },
    userCanDelete: {
      type: Boolean,
      default: false
    },
    isSuperUser: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isDirty: {
      type: Boolean,
      default: false
    },
    lock: {
      type: Boolean,
      default: false
    },
    simpleError: {
      type: String,
      default: ''
    },
    successPersistenceMessagesArray: {
      type: Array,
      default: () => []
    },
    successResourcesMessagesArray: {
      type: Array,
      default: () => []
    },
    triggerForceLogicPersistence: {
      type: Boolean,
      default: false
    },
    triggerForceLogicResources: {
      type: Boolean,
      default: false
    },
    currentlyOpenDeleteAction: {
      type: Number,
      default: 0
    },
    deleteSuccess: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      entitiesData: [],
      userRelationsLevel: 3,
      maxRelationsLevel: 5,
      isDiagramLoading: false,
      entityIdSelectedForGenerate: null,
      showGenerationModal: false
    }
  },
  computed: {
    relationsLevelValues() {
      return [...Array.from({ length: this.maxRelationsLevel + 1 }, (_, index) => index), 'All']
    },
    allEntitiesIds() {
      return this.entitiesData.map((x) => x.id)
    },
    userRoles() {
      const user = JSON.parse(localStorage.userData)

      return user.roles
    },
    canUserEditSelectedForGenerateEntity() {
      if (this.entityIdSelectedForGenerate === this.id) {
        return this.userCanEdit
      } else if (this.isSuperUser) {
        return true
      } else {
        const selectedEntityData = this.entitiesData.find((x) => x.id === this.entityIdSelectedForGenerate)

        const entityEditRolesIds = selectedEntityData.roles.filter(
          (x) => x.permissionType === 'EDIT').map((y) => y.role.name)

        return this.userRoles.some((userRoleName) => entityEditRolesIds.includes(userRoleName))
      }
    },
    canUserDeleteSelectedForGenerateEntity() {
      if (this.entityIdSelectedForGenerate === this.id) {
        return this.userCanDelete
      } else if (this.isSuperUser) {
        return true
      } else {
        return !!this.userRoles.find((x) => x.name === 'ENTITY_DELETER')
      }
    }
  },
  watch: {
    userRelationsLevel() {
      this.entitiesData = []
      this.renderDiagram()
    },
    '$vuetify.theme.isDark': {
      handler() {
        this.renderDiagram()
      }
    },
    showGenerationModal(val) {
      document.querySelectorAll('.cy-panzoom-zoom-button')
        .forEach((x) =>  x.style.display = val ? 'none' : 'block')
      if (val === false) {
        // Reset diagram to update persistence indicator
        this.onResetClick(false)
      }
    }
  },
  mounted() {
    this.renderDiagram()
  },
  methods: {
    async renderDiagram() {
      this.isDiagramLoading = true

      let persistenceExists = false

      try {

        const persistenceValidation = await validatePersistence({ id: this.id })
  
        if (persistenceValidation.status === 200) {
          persistenceExists = persistenceValidation.data.data.entities.find((ent) => ent.version.persistence)
        }
        this.entitiesData.push({
          id: this.id,
          name: this.name,
          fields: this.fields,
          persistenceExists
        })
      } catch (err) {
        console.log(`Error by validating entity ${id}`, err)
      }

      // Add parents entities
      const parentPromises = this.usages.map((parent) => {
        return this.addRelatedEntity(parent.entityId, 1)
      })

      let fieldPromises = Promise.resolve()

      if (this.fields.length) {
        const fieldsWithRelations = this.fields.filter((x) => x.relation && x.relation.id) || []

        // Add children entities
        if (fieldsWithRelations.length) {
          const childEntitiesIds = fieldsWithRelations.map((x) => x.relation.id)

          fieldPromises = Promise.all(childEntitiesIds.map((childId) => this.addRelatedEntity(childId, 1)))
        }
      }

      Promise.all([...parentPromises, fieldPromises])
        .then(() => {
          this.$nextTick(() => {
            cytoscape.use(cytoscapeDomNode)
            panzoom(cytoscape)

            const firstEntitySize = this.entitiesData[0].fields.length

            const largestestEntitySize = this.entitiesData.reduce((a, b) => {
              return a.fields.length > b.fields.length ? a : b
            }).fields.length

            const defaultYPan = firstEntitySize > 10 ? 
              firstEntitySize * 6 : 
              firstEntitySize * 20

            const diagram = cytoscape({
              container: document.getElementById('diagram'),
              elements: [],
              pan: { x: 0, y: defaultYPan },
              wheelSensitivity: 0.2,
              style: [
                {
                  selector: 'node',
                  style: {
                    shape: 'rectangle',
                    'background-opacity': 0
                  }
                },
                {
                  selector: 'edge',
                  style: {
                    'label': 'data(text)',
                    'target-arrow-shape': 'triangle',
                    'curve-style': 'straight',
                    'color': this.$vuetify.theme.isDark ? 'white' : 'black'
                  }
                }
              ]
            })

            diagram.domNode()
            const pan = diagram.panzoom({
              zoomOnly: true,
              fitPadding: 0
            })

            const entitiesElementsArray = []

            // Add entity nodes
            this.entitiesData.forEach((entityData) => {
              entitiesElementsArray.push(this.renderEntityNode(entityData))
            })

            // Add relations if any, but only between added entities
            this.entitiesData.forEach((entityData) => {
              entityData.fields.forEach((field) => {
                if (field.relation && this.allEntitiesIds.includes(field.relation.id)) {
                  entitiesElementsArray.push({
                    group: 'edges',
                    data: {
                      id: `relation-${entityData.id}-${field.relation.id}`,
                      source: `entity-node-${entityData.id}`,
                      target: `entity-node-${field.relation.id}`,
                      text: `${field.name}`,
                      'target-arrow-shape': 'triangle',
                      'curve-style': 'straight'
                    }
                  })
                }
              })
            })

            diagram.ready(() => {
              diagram.add(entitiesElementsArray)
              diagram.layout({
                name: 'circle',
                spacingFactor: largestestEntitySize > 10 && this.userRelationsLevel > 1 ? 2.4 : 1.4,
                fit: false,
                pan: { x: 0, y: defaultYPan }
              }).run()
              pan.fit( pan.elements(), this.userRelationsLevel === 0 ? 150 : 50 )
              this.isDiagramLoading = false
            })

            if (this.entitiesData.length <= 1) {
              diagram.zoom({
                level: 1,
                renderedPosition: { x: 350, y: defaultYPan + 100 }
              })
            }
          })
        })
    },
    async addRelatedEntity(id, level = 0) {
      if (this.userRelationsLevel !== 'All' 
        && (level > this.userRelationsLevel 
        || level > this.maxRelationsLevel)) {
        return []
      }

      if (this.allEntitiesIds.includes(id)) {
        return []
      }
      
      try {
        const entity = await getEntity({ id })
        const entityData = entity.data.data
          
        const entityRoles = entityData.roles?.map((item) => item.role.name)

        if (this.userRoles.some((role) => entityRoles.includes(role.name))) {
          let persistenceExists = false

          try {

            const persistenceValidation = await validatePersistence({ id: entityData.id })
  
            if (persistenceValidation.status === 200) {
              persistenceExists = persistenceValidation.data.data.entities.find((ent) => ent.version.persistence)
            }
                  
          } catch (err) {
            console.log(`Error by validating entity ${id}`, err)
          }

          const entityDataToPush = {
            id: entityData.id,
            name: entityData.name,
            fields: entityData.fields,
            roles: entityData.roles,
            persistenceExists
          }

          this.entitiesData.push(entityDataToPush)
              
          const promises = []

          if (entity?.usages?.length) {
            const parentPromises = entity.usages.map((parent) => {
              return this.addRelatedEntity(parent.entityId, level + 1)
            })

            promises.push(...parentPromises)
          }
          if (entity.fields?.length) {
            const childEntitiesIds = entity.fields
              .filter((f) => f.relation && f.relation.id)
              .map((f) => f.relation.id)

            const childPromises = childEntitiesIds.map((childId) => {
              return this.addRelatedEntity(childId, level + 1)
            })

            promises.push(...childPromises)
          }

          return Promise.all(promises)
        } else {
          return []
        }
      } catch (err) {
        console.log(`Error by fetching entity ${id}`, err)
      }

      return true
    },
    renderEntityNode(entityData) {
      const { id, name, persistenceExists } = entityData

      const node = document.createElement('div')

      const nodeId = `entity-node-${id}`

      node.id = nodeId
      node.classList.add('entity-node')
      node.classList.add(this.$vuetify.theme.isDark ? 'entity-node_dark' : 'entity-node_light')

      if (id === this.id) {
        node.classList.add('entity-node-current')
      }

      const entityNodeWrapper = document.createElement('div')

      entityNodeWrapper.classList.add('entity-node-wrapper')

      const entityNameRow = document.createElement('div')

      entityNameRow.classList.add('entity-name-row')

      entityNameRow.innerHTML = `
          ${this.renderIconHTMLString('circle')}
          <span class='entity-name'><strong>${name}</strong></span>
        `

      entityNodeWrapper.appendChild(entityNameRow)

      let entityFieldsInnerHTML = ''

      const entityFieldsWrapper = document.createElement('div')

      entityData.fields.forEach((field, index) => {
        if (index === 0) {
          entityFieldsInnerHTML += '<div class=\'entity-fields\'>'
        }

        if (!field.primaryKey) {
          const fieldHTML = this.renderAttributeField(field, false)

          entityFieldsInnerHTML += fieldHTML
        }

        if (index === entityData.fields.length - 1) {
          entityFieldsInnerHTML += '</div>'
        }
      })

      // Primary key
      entityData.fields.forEach((field) => {
        if (!field.primaryKey) return
        const fieldHTML = this.renderAttributeField(field, true)

        entityFieldsInnerHTML += fieldHTML
      })

      entityFieldsWrapper.innerHTML = entityFieldsInnerHTML
      entityNodeWrapper.appendChild(entityFieldsWrapper)

      const nodeFooterWrapper = document.createElement('div')

      nodeFooterWrapper.classList.add('entity-node-footer-wrapper')
      const generateButton = this.renderGenerateButton(id, this.generate)
      const persistenceIcon = this.renderPersistenceIndicator(id, persistenceExists)

      nodeFooterWrapper.appendChild(persistenceIcon)
      nodeFooterWrapper.appendChild(generateButton)
      
      entityNodeWrapper.appendChild(nodeFooterWrapper)

      node.appendChild(entityNodeWrapper)

      if (id !== this.id) {
        node.addEventListener('dblclick', () => {
          const routeData = this.$router.resolve({
            name: 'entityEdit',
            params: { id }
          })

          window.open(routeData.href, '_blank')
        })
      }

      return {
        group: 'nodes',
        data: {
          'id': nodeId,
          'label': name,
          'dom': node
        }
      }

    },
    renderAttributeField(field, isPrimaryKey = false) {
      return `
      <div class='entity-field-row'>
        <div class='entity-field-name-wrapper'>${this.renderIconHTMLString(`circle ${isPrimaryKey ? 'fa-solid' : 'fa-regular'}`)}
          <span class='entity-field-name'>${field.name}</span>
          </div>
          <span class='entity-field-dataType'>${field.dataType.toLowerCase()}</span>
          </div>
          `
    },
    renderIconHTMLString(iconName) {
      return `<i class='fa fa-${iconName} entity-name-icon'></i>`
    },
    renderGenerateButton(entityId) {
      const button = document.createElement('button')
    
      button.className = 'entity-button entity-button-generate'
      button.textContent = 'Generate'
          
      button.onclick = this.openGenerationModal.bind(this, entityId)
    
      return button
    },
    renderPersistenceIndicator(entityId, persistenceExists) {
      const icon = document.createElement('i')
    
      icon.innerHTML = persistenceExists ? 'Persistence exists' : '<div class="error--text">No persistence</div>'
          
      icon.onclick = this.openGenerationModal.bind(this, entityId)
    
      return icon
    },
    openGenerationModal(id) {
      this.entityIdSelectedForGenerate = id
      this.showGenerationModal = true
    },
    onResetClick(resetRelationsLevel = true) {
      this.entitiesData = []
      if (resetRelationsLevel) this.userRelationsLevel = 3
      this.renderDiagram()
    },
    async savePng() {
      const canvas = await html2canvas(document.getElementById('diagram'), {
        backgroundColor: this.$vuetify.theme.currentTheme.background
      })
      const url = canvas.toDataURL('image/png')

      download(url, `${this.name}.png`)
    },
    deletePersistence() {
      this.$emit('deletePersistenceFunct', this.entityIdSelectedForGenerate)
    },
    deleteResources(isForceDelete) {
      this.$emit('deleteResourcesFunct', isForceDelete, this.entityIdSelectedForGenerate)
    },
    resetValidateGenerateDelete() {
      this.$emit('resetValidateGenerateDelete')
    },
    closeGenerationDialog() {
      this.showGenerationModal = false
      this.entityIdSelectedForGenerate = null
    }
  }
}
</script>
<style lang="scss">
@import '~cytoscape-panzoom/cytoscape.js-panzoom.css';

#diagram {
  width: 100%;
  height: calc(100vh - 335px);
}

.entity {
  &-node {
    min-width: 200px;
    background-color: var(--v-background-base);
    border: 1px solid var(--v-secondary-base);
    border-radius: 8px;
    color: var(--v-secondary-base);
    font-size: 14px;

    &_dark {
      color: var(--v-secondary-dark-base);
    }

    &_light {
      color: var(--v-secondary-light-base);
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
    }

    &-current {
      border-color: var(--v-primary-base);
      color: var(--v-primary-base)
    }
  }

  &-name {
    &-row {
      display: flex;
      align-items: center;
      padding: 5px;
      border-bottom: 1px solid var(--v-primary-base);
    }

    &-icon {
      margin-right: 5px;
    }
  }

  &-fields {
    border-bottom: 1px double var(--v-primary-base);
  }

  &-field {
    &-name {
      &-wrapper {
        display: flex;
        align-items: center;
      }
    }
    &-row {
      display: flex;
      justify-content: space-between;
      padding: 5px;
    }
  }

  &-node-footer {
    
    &-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 6px 10px;
      border-top: 1px solid var(--v-primary-base);
    }
  }

  &-button-generate {
    color: var(--v-info-base);
    cursor: pointer;
  }
}

.cy-panzoom {
  &-zoom-button {
    width: 30px;
    height: 30px;
    background-color: var(--v-primary-base);
    color: #fff;
    border: none;
    border-radius: 90px;
    left: 20px;
  }
  
  &-zoom-in {
    top: 60px !important;
  }

  &-zoom-out {
    top: 100px !important;
  }

  & .svg-inline--fa.icon {
    margin-top: 10px !important;
    margin-left: 1px !important;
  }
}

.svg-inline--fa {
  vertical-align: -0.6em;
}

.relations-level-diagram-select {
  max-width: 120px;
  margin-left: 20px !important;

  .v-text-field__details {
    display: none;
  }
}
</style>
