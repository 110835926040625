<template>
  <v-row no-gutters>
    <v-col
      class="pr-1"
    >
      <v-row no-gutters>
        <v-col
          cols="12"
          lg="5"
          class="pr-lg-1"
        >
          <div class="d-inline-flex max-width">
            <p class="pt-2 pr-2 text-warning color-accent">{{ index + 1 }}.</p>
            <v-text-field
              v-model="field.name"
              outlined
              dense
              :label="$lang.labels.name"
              required
              class="required-asterisk copy-name-icon"
              :rules="[
                v => !!v || $lang.labels.required,
                v => v.length <= 30 || $lang.errors.max30Chars,
                v => /^[A-Za-z0-9_]+$/g.test(v) || $lang.errors.onlyLettersNumbersUnderscore,
                v => v && v !== 'page' || $lang.errors.reservedPage,
                v => v && v !== 'size' || $lang.errors.reservedSize
              ]"
              :readonly="!userCanEdit"
              :data-cy="`${index}-entity-field-name`"
              append-icon="mdi-vector-combine"
              @click:append="copyFieldName()"
              @input="onFieldNameChange"
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="3"
          class="px-lg-1"
        >
          <v-select
            v-model="field.dataType"
            required
            class="required-asterisk"
            :rules="[v => !!v || $lang.labels.required]"
            :items="dataTypesFormatted"
            :label="isRelation ? $lang.labels.foreignKey : $lang.labels.dataType"
            :disabled="isRelation"
            :readonly="!userCanEdit"
            outlined
            dense
            item-text="text"
            item-value="value"
            :data-cy="`${index}-entity-field-data-type`"
            @change="onDataTypeChange"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title :data-cy="`${index}-${item.value} data-type`">{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-select>
        </v-col>
        <v-col
          cols="12"
          lg="4"
          class="px-lg-1"
        >
          <v-text-field
            v-if="field.required"
            v-model="field.defaultValue"
            outlined
            dense
            disabled
            :placeholder="$lang.labels.cantSetDefaultValueIfRequired"
            :label="$lang.labels.cantSetDefaultValueIfRequired"
            :readonly="!userCanEdit"
            :data-cy="`${index}-entity-field-default-value-disabled`"
          ></v-text-field>
          <v-select
            v-if="field.dataType === 'BOOLEAN' && !field.required"
            v-model="field.defaultValue"
            :items="['true', 'false']"
            :label="$lang.labels.defaultValue"
            :readonly="!userCanEdit"
            outlined
            dense
            :data-cy="`${index}-entity-field-boolean`"
          ></v-select>
          <v-text-field
            v-else-if="field.dataType === 'VARCHAR' && !field.required"
            v-model="field.defaultValue"
            outlined
            dense
            :label="$lang.labels.defaultValue"
            :readonly="!userCanEdit"
            :data-cy="`${index}-entity-field-varchar`"
          ></v-text-field>
          <v-text-field
            v-else-if="field.dataType === 'TIMESTAMP' && !field.required"
            v-model="field.defaultValue"
            outlined
            dense
            :label="$lang.labels.defaultValue"
            :readonly="!userCanEdit"
            :rules="[v => v && v.length > 0 ? /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}(?:\.\d{1,9})?$/.test(v) || 'Invalid timestamp format!' : true]"
            hint="Example value: 2021-01-01 00:00:00"
            :data-cy="`${index}-entity-field-timestamp`"
          ></v-text-field>
          <v-text-field
            v-else-if="(field.dataType === 'BIGINT' || field.dataType === 'INTEGER' || field.dataType === 'DOUBLE' || field.dataType === 'FLOAT') && !field.required"
            v-model="field.defaultValue"
            type="number"
            outlined
            dense
            class="hideNumberArrows"
            :label="$lang.labels.defaultValue"
            :readonly="!userCanEdit"
            :data-cy="`${index}-entity-field-number`"
          ></v-text-field>
          <v-text-field
            v-else-if="field.dataType === 'JSON' && !field.required"
            v-model="field.defaultValue"
            outlined
            dense
            :label="$lang.labels.defaultValue"
            readonly
            class="ellipsis-field"
            append-icon="mdi-pencil"
            :data-cy="`${index}-entity-field-json`"
            @click="openJSONEditor"
            @click:append="openJSONEditor"
          ></v-text-field>
          <v-dialog v-model="showJSONEditor" max-width="75%">
            <v-card class="pa-2" flat>
              <p class="pb-0 mb-0">{{ $lang.header.value }}</p>
              <codemirror v-model="JSONcode" :options="cmOptions" />
              <div class="d-flex w-100 justify-end mt-1">
                <v-btn
                  color="primary"
                  text
                  :data-cy="`${index}-entity-field-json-cancel`"
                  @click="showJSONEditor = false"
                >
                  {{ $lang.actions.cancel }}
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  :disabled="!JSONcode"
                  :data-cy="`${index}-entity-field-json-save`"
                  @click="saveJSONDefaultValue()"
                >
                  {{ $lang.actions.save }}
                </v-btn>
              </div>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          lg="5"
          class="pl-lg-4 pr-lg-1"
        >
          <div class="d-inline-flex max-width">
            <v-select
              v-model="field.searchType"
              :items="searchTypesFormatted"
              :label="$lang.labels.searchType"
              outlined
              dense
              item-text="text"
              item-value="value"
              required
              :data-cy="`${index}-entity-field-search-type`"
              class="required-asterisk"
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!userCanEdit"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title :data-cy="`${index}-${item.value} search-type`">{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="3"
          class="pl-lg-1 pr-lg-1"
        >
          <v-select
            v-model="field.matchingPattern"
            hide-details
            :items="matchingPatternsFormatted"
            :placeholder="$lang.labels.cantSetIfFieldIsNoSearchable"
            :label="field.searchType === 'NO'
              ? $lang.labels.cantSetIfFieldIsNoSearchable
              : $lang.labels.searchMatchingPattern"
            outlined
            dense
            required
            class="required-asterisk"
            :disabled="!['VARCHAR', 'BIGINT', 'INTEGER', 'DOUBLE', 'FLOAT', 'TIMESTAMP'].includes(field.dataType) || field.searchType === 'NO'"
            item-text="text"
            item-value="value"
            :data-cy="`${index}-entity-field-matching-pattern`"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title :data-cy="`${index}-${item.value} matching-pattern`">{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-select>
        </v-col>
        <v-col
          cols="12"
          class="mt-2 pl-4"
        >
          <v-row no-gutters justify="space-between" align="center">
            <v-checkbox
              v-model="isRelation"
              hide-details
              :data-cy="`${index}-entity-field-is-relation`"
              :label="$lang.labels.relation"
              class="mt-0"
            ></v-checkbox>
            <div class="d-inline-flex justify-end align-center">
              <v-checkbox
                v-if="entityType === 'JDBC'"
                v-model="field.primaryKey"
                hide-details
                :label="$lang.labels.primaryKey"
                class="mt-0"
                :data-cy="`${index}-entity-field-primary-key`"
                @change="field.primaryKey ? (field.unique = true, field.required = true) : ''"
              ></v-checkbox>
              <v-checkbox
                v-model="field.required"
                :disabled="field.primaryKey"
                hide-details
                :label="$lang.labels.required"
                :data-cy="`${index}-entity-field-is-required`"
                class="ml-4 mt-0"
              ></v-checkbox>
              <v-checkbox
                v-model="field.unique"
                :disabled="field.primaryKey"
                hide-details
                :label="$lang.labels.unique"
                class="ml-4 mt-0"
                :data-cy="`${index}-entity-field-is-unique`"
              ></v-checkbox>
              <v-checkbox
                v-model="field.sortable"
                hide-details
                :label="$lang.labels.sortable"
                class="ml-4 mt-0"
                :data-cy="`${index}-entity-field-sortable`"
              ></v-checkbox>
              <v-btn
                v-if="userCanEdit"
                color="error"
                class="color-error-text ml-2"
                icon
                :data-cy="`${index}-entity-field-remove`"
                :disabled="isDeleteButtonDisabled"
                @click="$emit('removeField', true)"
              >
                <v-icon size="25">mdi-trash-can-outline</v-icon>
              </v-btn>

            </div>
          </v-row>
        </v-col>
        <v-col
          v-if="isRelation"
          cols="12"
          class="mt-2 pl-4"
        >
          <v-row no-gutters>
            <v-col
              cols="12"
            >
              <div class="d-flex">
                <v-autocomplete
                  v-model="field.relation.id"
                  outlined
                  dense
                  :items="entityList"
                  :loading="isLoadingEntities"
                  :search-input.sync="searchEntities"
                  clearable
                  hide-no-data
                  hide-details
                  item-text="name"
                  item-value="id"
                  :label="$lang.labels.entity"
                  :placeholder="$lang.actions.startTyping"
                  prepend-inner-icon="mdi-cloud-search-outline"
                  :readonly="!userCanEdit"
                  required
                  class="required-asterisk mb-2"
                  :rules="[v => !!v || $lang.labels.required]"
                  append-icon="mdi-refresh"
                  :data-cy="`${index}-entity-field-relation`"
                  @click:append="searchEntitiesFunction(searchEntities)"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title :data-cy="`${index}-${item.id} relation`">{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <v-btn
                  text
                  class="ml-1 mt-1"
                  color="primary"
                  :disabled="!field.relation.id"
                  :data-cy="`${index}-entity-field-open-relation-entity`"
                  @click="openEntity(field.relation.id)"
                >
                  {{ $lang.actions.openTemplate }}
                </v-btn>
              </div>
            </v-col>
            <v-col
              cols="12"
            >
              <v-select
                v-model="field.relation.type"
                hide-details
                :items="typesFormatted"
                :label="$lang.labels.type"
                outlined
                dense
                required
                class="required-asterisk mb-2"
                :rules="[v => !!v || $lang.labels.required]"
                item-text="text"
                item-value="value"
                :data-cy="`${index}-entity-field-relation-type`"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title :data-cy="`${index}-${item.value} relation-type`">{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="mt-2 mb-5"
        >
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </v-col>
    <div
      class="pl-2"
    >
      <div class="d-flex flex-column align-end">
        <v-btn
          :disabled="!isMovableUp"
          color="primary"
          icon
          small
          class="mb-1"
          :data-cy="`${index}-entity-field-move-up`"
          @click="moveField('up')"
        >
          <v-icon
            size="25"
          >mdi-arrow-up</v-icon>
        </v-btn>
        <v-btn
          :disabled="!isMovableDown"
          color="primary"
          icon
          small
          class="mt-1"
          :data-cy="`${index}-entity-field-move-down`"
          @click="moveField('down')"
        >
          <v-icon
            size="25"
          >mdi-arrow-down</v-icon>
        </v-btn>
      </div>
    </div>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'

import {
  getEntityByIdUsingGET as getEntity,
  getEntitiesUsingGET as getEntities
} from '@/utils/api'
import copy from 'copy-to-clipboard'
import { definitions } from '@/utils/definitions'

import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/ayu-mirage.css'
import 'codemirror/theme/3024-day.css'
import 'codemirror/addon/lint/lint.css'
import 'codemirror/addon/hint/show-hint.css'

// import js
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/groovy/groovy.js'
import 'codemirror/mode/python/python.js'
import 'codemirror/addon/lint/lint.js'
import 'codemirror/addon/lint/javascript-lint.js'
import 'codemirror/addon/hint/javascript-hint.js'
import 'codemirror/addon/hint/show-hint.js'

import '../../assets/scss/code-mirror.scss'

export default {
  components: {
    codemirror
  },
  props: {
    fieldData: {
      type: Object,
      default: () => {}
    },
    userCanEdit: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    isMovableUp: {
      type: Boolean,
      default: true
    },
    isMovableDown: {
      type: Boolean,
      default: true
    },
    entityType: {
      type: String,
      default: 'JDBC'
    },
    primaryKey: {
      type: Boolean,
      default: false
    },
    usages: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      valid: false,
      lock: true,
      field: {
        name: '',
        dataType: 'VARCHAR',
        searchType: 'NO',
        relation: {
          id: 0,
          type: 'ONE_TO_ONE',
          entityRequest: null
        },
        primaryKey: false,
        required: false,
        sortable: false,
        unique: false,
        defaultValue: null,
        matchingPattern: ''
      },
      isRelation: false,
      isLoadingEntities: false,
      entityList: [],
      searchEntities: '',
      dataTypes: definitions.EntityField.properties.dataType.enum,
      searchTypes: definitions.EntityField.properties.searchType.enum,
      types: definitions.RelationId.properties.type.enum,
      matchingPatterns: definitions.EntityFieldRequest.properties.matchingPattern.enum,
      showJSONEditor: false,
      cmOptions: {
        gutters: [],
        tabSize: 4,
        mode: 'javascript',
        theme: this.$vuetify.theme.dark ? 'ayu-mirage' : '3024-day',
        lineNumbers: true,
        line: true,
        lineWrapping: true,
        autocorrect: true,
        autocomplete: true,
        highlightLines: true,
        viewportMargin: Infinity,
        matchBrackets: true,
        autoCloseBrackets: true
      },
      JSONcode: ''
    }
  },
  computed: {
    isDeleteButtonDisabled() {

      return this.primaryKey && this.usages.length > 0
    },
    matchingPatternsFormatted () {
      const localMatchingPatterns = []

      if (this.field.dataType === 'VARCHAR') {
        localMatchingPatterns.push('EXACT')
        localMatchingPatterns.push('BEGINNING')
        localMatchingPatterns.push('END')
        localMatchingPatterns.push('EVERYWHERE')
      }

      if (['BIGINT', 'INTEGER', 'DOUBLE', 'FLOAT', 'TIMESTAMP'].includes(this.field.dataType)) {
        localMatchingPatterns.push('EXACT')
        localMatchingPatterns.push('BETWEEN')
      }

      if (this.field.dataType === 'JSON' || this.field.dataType === 'BOOLEAN') {
        localMatchingPatterns.push('EXACT')
      }

      return localMatchingPatterns.map((item) => {
        return { text: this.$lang.status[item], value: item }
      })
    },
    dataTypesFormatted () {
      return this.dataTypes.map((item) => {
        return { text: this.$lang.status[item], value: item }
      })
    },
    searchTypesFormatted () {
      return this.searchTypes.map((item) => {
        return { text: this.$lang.status[item], value: item }
      })
    },
    typesFormatted () {
      return this.types.map((item) => {
        return { text: this.$lang.status[item], value: item }
      })
    }
  },
  watch: {
    searchEntities: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchEntitiesFunction(val)
        }
      }
    },
    field: {
      handler() {
        this.$emit('updateField', this.field)
      },
      deep: true
    },
    'field.relation.id': {
      handler(val) {
        if (val && !this.lock) {
          getEntity({ id: val })
            .then((res) => {
              const findPrimaryKey = res.data.data.fields.find((item) => item.primaryKey)

              if (findPrimaryKey) this.field.dataType = findPrimaryKey.dataType
            })
            .catch((err) => {
              this.addSnackbar({
                message: err,
                timeout: 5000,
                color: 'error'
              })
            })
        }
      }
    },
    isRelation: {
      handler(val) {
        if (!val) {
          this.entityList = []
          this.searchEntities = ''
          this.field.relation = {
            id: 0,
            type: 'ONE_TO_ONE',
            entityRequest: null
          }
        } else {
          if (this.field?.relation === null) {
            this.field.relation = {
              id: 0,
              type: 'ONE_TO_ONE',
              entityRequest: null
            }
          }
        }
      }
    },
    'field.required': {
      handler(val) {
        if (val) {
          this.field.defaultValue = null
        }
      }
    },
    'field.dataType': {
      handler(val) {
        if (val !== 'VARCHAR') {
          if (this.field.searchType === 'NO') {
            if (!this.lock) this.field.matchingPattern = null
          } else {
            if (!this.lock) this.field.matchingPattern = 'EXACT'
          }
        } else {
          if (!this.lock) this.field.matchingPattern = 'EXACT'
        }
      }
    },
    'field.searchType': {
      handler(val) {
        if (val === 'NO') {
          if (!this.lock) this.field.matchingPattern = null
        } else {
          if (!this.lock) this.field.matchingPattern = 'EXACT'
        }
      }
    }
  },
  created() {
    this.field = { ...this.fieldData }

    if (this.field?.relation?.id) {
      this.isRelation = true
      this.isLoadingEntities = true

      getEntity({ id: this.field.relation.id })
        .then((res) => {
          this.searchEntities = res.data.data.name
          this.entityList = [res.data.data]

          this.isLoadingEntities = false

          this.lock = false
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
          this.isLoadingEntities = false

          this.lock = false
        })
    } else {
      setTimeout(() => this.lock = false, 50)
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),
    onFieldNameChange() {
      this.$emit('updateField', this.field)
      this.$emit('check-duplicates')
    },
    checkForDuplicateName() {
      this.$emit('checkForDuplicateName', this.field.name)
    },
    onDataTypeChange() {
      this.field.defaultValue = null
    },
    moveField(direction) {
      this.$emit('moveField', direction)
    },
    openEntity(id) {
      window.open(`/${localStorage.selectedLanguage || 'en'}/entities/edit/${id}`, '_blank')
    },
    searchEntitiesFunction(val = '') {

      if (!val || val.length < 2 || this.lock) return

      this.isLoadingEntities = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getEntities(obj)
        .then((res) => {
          this.entityList = res.data.data.items
          this.isLoadingEntities = false
        })
        .catch((error) => {
          this.isLoadingEntities = false
          this.addSnackbar({
            message: error,
            timeout: 5000,
            color: 'error'
          })
          console.log(error)
        })
    },
    copyFieldName() {
      if (this.field.name) {
        copy(this.field.name)
        this.addSnackbar({
          message: this.$lang.success.copiedClipboard,
          timeout: 5000,
          color: 'success'
        })
      } else {
        this.addSnackbar({
          message: this.$lang.errors.nothingToCopy,
          timeout: 5000,
          color: 'error'
        })
      }
    },
    openJSONEditor() {
      console.log('this.field.defaultValue', this.field.defaultValue)
      this.JSONcode = this.field.defaultValue
      this.showJSONEditor = true
    },
    saveJSONDefaultValue() {
      this.showJSONEditor = false
      this.field.defaultValue = this.JSONcode
    }
  }
}
</script>

<style>
.copy-name-icon .v-icon{
  color: #009fb7;
}
.ellipsis-field input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.vue-codemirror {
  height: 40vh;
  width: 100%;
}
.CodeMirror-line  > span {
  padding-left: 20px;
}
</style>
